<template>
  <div class="page">
    <div class="buttom1" @click="beforeClose"></div>
    <div class="box overlayclass">
      <div style="padding: 30px 10px 0 10px">
        <div class="plateUls">
          <div class="headerTitle">
            <span></span>
            複賽指引
            <span></span>
            <img :src="require('@/assets/image/open.png')" alt="" />
          </div>
          <div class="formCenternt">
            <p class="P_1">
              請按照下方參賽影片要求進行錄製，否則將影響評分。如提交影片遇到困難，請于活動頁左下方WhatsApp進行留言，主辦機構中文路ChineseRd將會提供支援。
            </p>
            <p class="P_2">
              *凡是參賽的選手，等同於同意主辦機構中文路ChineseRd將此次參賽影片使用於全媒體渠道展示宣傳。
            </p>
            <p class="P_2">*為免遇上網絡擠塞，請預留好上載複賽影片的時間。</p>
            <p class="P_2">
              *所有參賽影片錄製必須為參賽者本人，如審核發現非參賽者本人錄製之情況，將予以失格公示。
            </p>
            <!-- 參賽影片要求 -->
            <div class="uls">
              <div class="ulsTitle">
                <div class="left_icon">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>

                <div>參賽影片要求</div>
                <div class="right_icon">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <!-- <span class="xuhao">① </span> -->
                  1. 參賽者應依照以下次序錄製影片：
                </p>
                <p class="P_3">
                  ① 用普通話進行自我介紹，包含姓名、組別、來自哪一間幼稚園/小學/中學等。
                </p>
                <p class="P_3">② 用普通話讀出比賽誦材之標題及作者（如若誦材未標明作者，可以只讀誦材標題）。</p>
                <p class="P_3">③ 用普通話朗誦誦材內容一次。</p>
                <p class="P_3">④ 參賽者必須背誦誦材，違者將只獲評語，沒有評級，亦不獲發獎狀。</p>
                <p class="P_3">⑤ 如參賽者於影片中重複演繹同一作品，只有首次演繹獲得評審。</p>
                <p class="P_3">⑥ 請使用豎屏拍攝。</p>
                <p class="P_3">⑦ 影片的時長要求：幼稚園組、小學組3分鐘以內，中學組5分鐘以內。</p>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <!-- <span class="xuhao">② </span> -->
                  2. 參賽者必須根據以下指引，否則將影響評分：
                </p>
                <p class="P_3">① 參賽者錄製參賽影片時請注意着裝得體。</p>
                <p class="P_3">② 整段影片須清楚顯示參賽者之全身、樣貌及動作。</p>
                <p class="P_3">③ 拍攝影片時，應保持拍攝鏡頭穩定及定鏡拍攝，不應移動、推進及拉近鏡頭。</p>
                <p class="P_3">④ 為確保影片聲畫同步、畫面與聲音清晰和流暢，影⽚解像度為720p(1280x720)或以上。</p>
                <p class="P_3">⑤ 在室內以簡單背景和寧靜的環境下拍攝，並確保光度充足，避免背光、使用虛擬或投射背景。</p>
                <p class="P_3">⑥ 參賽者不應使用任何款式耳機作為收音用途。</p>
                <p class="P_3">⑦ 拍攝影片時請勿使用擴音器。</p>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <!-- <span class="xuhao">③ </span> -->
                  3. 參賽者必須跟隨以下指引，否則將被取消資格：
                </p>
                <p class="P_3">
                  ① 影片必須為一鏡到底的拍攝原片，拍攝途中不可暫停攝影機。
                </p>
                <p class="P_3">
                  ② 影片中不可加入特別效果，例如濾鏡、過渡、調色、調光、字幕等，亦不得進行任何混音、剪接及後期製作。 
                </p>
                <p class="P_3">
                  ③ 影片必須是現場收音及錄像，不可配音。
                </p>
              </div>
            </div>
            <!-- 影片上傳要求 -->
            <div class="uls">
              <div class="ulsTitle">
                <div class="left_icon">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>

                <div>影片上傳要求</div>
                <div class="right_icon">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <span class="xuhao">① </span>
                  參賽者須通過本次活動報名系統登錄並上傳參賽影片。
                </p>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <span class="xuhao">② </span
                  >參賽者有責任確保以下資料正確，包括：
                </p>
                <p class="P_3">
                  1.網頁中的各項資料正確。如發現資料有誤，請于活動頁左下方WhatsApp進行留言，主辦機構中文路ChineseRd將會提供支援，根據指引於限期內重新提交。
                </p>
                <p class="P_3">
                  2.所提交的影片須與參賽者及其比賽項目相符。如記錄不符，評審團有權不評審相關影片。
                </p>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <span class="xuhao">③ </span
                  >如重複提交，將以最後一次符合提交條件 的記錄為準。
                </p>
              </div>
              <div class="ulsCentent2">
                <p class="title">
                  <span class="xuhao">④ </span
                  >如評審期內因任何原因，影片未能播放，
                  不會就此作個別通知，參賽者將不獲評分 （評級）或獎狀。
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="upload_button">
            <div class="but" @click="handleUploadVideo()">
              <img :src="require('@/assets/image/fusai/yunshang.png')" />
              點擊上載視頻
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RematchModal',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    beforeClose() {
      this.$emit('cancel')
      this.isSuccess = false
    },
    handleUploadVideo(e) {
      const currentPath = this.$route.path
      const isAchievementRoute = currentPath.includes('achievement')
      if (isAchievementRoute) {
        this.$router.push({
          path: '/',
          query: {
            uploadVideo: true
          }
        })
      } else {
        this.beforeClose()
      }
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;

  .buttom1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 777;
  }

  .box {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    max-height: 100%;
    overflow-y: auto;
    z-index: 888;
    transform: translate3d(-50%, -50%, 0);
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
}
.overlayclass {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plateUls {
  width: 100%;
  background: #ffd123;
  border-radius: 32px 32px 10px 10px;
  padding: 6px;
  box-sizing: border-box;
  // margin-bottom: 18px;

  .headerTitle {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    border-radius: 32px 32px 10px 10px;
    background-color: #eca300;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    text-shadow: 0px 2px 0px #8f6300;

    span {
      width: 20px;
      height: 3px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }

    span:nth-child(1) {
      margin-right: 20px;
    }

    span:nth-child(2) {
      margin-left: 20px;
    }

    img {
      width: 55.49px;
      height: 62.99px;
      position: absolute;
      left: 2%;
      top: -45%;
    }
  }

  .formCenternt {
    background-color: #fff;
    // border: 1px solid red;
    // width: 99;
    border-radius: 8px;
    padding: 30px 10px 60px 10px;
    box-sizing: border-box;
    text-align: left;
    max-height: 70vh;
    overflow-x: hidden;
    .P_1 {
      font-size: 14px;
      font-weight: 600;
      color: #ff5a25;
      line-height: 24px;
    }
    .P_2 {
      margin-top: 15px;
      font-size: 10px;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    .uls {
      width: 100%;
      margin-top: 20px;
      .ulsTitle {
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        border-radius: 8px;
        background-color: #ff5a25;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .right_icon {
          display: flex;
          align-items: center;
          div:nth-child(3) {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: #fff;
            margin-left: 7px;
          }
          div:nth-child(2) {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #fff;
            margin-left: 7px;
          }
          div:first-child {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #fff;
            margin-left: 7px;
          }
        }

        .left_icon {
          display: flex;
          align-items: center;
          div:first-child {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background-color: #fff;
            margin-right: 7px;
          }
          div:nth-child(2) {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: #fff;
            margin-right: 7px;
          }
          div:nth-child(3) {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #fff;
            margin-right: 7px;
          }
        }
      }
      .ulsCentent2 {
        margin-bottom: 10px;
        text-align: left;
        .title {
          color: rgba(64, 64, 64, 1);
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          padding-bottom: 5px;
        }
        .xuhao {
          font-size: 16px;
          line-height: 30px;
        }
        .P_3 {
          margin-top: 8px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: #484848;
        }
        .focus {
          color: #ff5a25;
        }
      }
    }
  }
  .upload_button {
    width: 100%;
    position: fixed;
    bottom: 15px;
    right: 0;
    z-index: 999;

    .but {
      width: 230px;
      height: 40px;
      line-height: 40px;
      background: #ff7e18;
      font-size: 14px;
      font-family: PingFang SC-Semibold, PingFang SC;
      border-radius: 6px 6px 6px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      margin: auto;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
  }
}
</style>
