export default {
  data() {
    return {
      //  比赛章程
      constitutionUrl:
        "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-04/e7bcc39b7ecd867074ab6a773fc3c0a0.pdf",
      //  团体比赛章程
      constitutionUrl_team:
        "https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-08/8c56a2847057a2823c91d064c4862eaa.pdf",
      //  app下载
      appUrl: "https://app.chineserd.com",
      // 赛事质询
      whatUrl: "https://wa.me/85291496563",
      // h5banner
      h5BannerUrl: require("@/assets/image/hodgepodge/h5banner.jpg"),
      // pcbanner
      pcBannerUrl: require("@/assets/image/hodgepodge/pcbanner.jpg"),
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
