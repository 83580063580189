<template>
	<div class="uploaderBox">
		<!-- 仅支持单文件上传 -->
		<van-uploader v-model="fileList" :accept="accept" :after-read="afterRead" :multiple="multiple" :max-count="1" :upload-text="uploadText" @delete="uploadDelete">
			<div class="but">
				<img src="@/assets/image/fusai/zhenglogo.png" v-if="logoaAtive == 0" />
				<img src="@/assets/image/fusai/okVideo.png" v-if="logoaAtive == 1" />
				<div class="buttext">{{ uploadText }}</div>
			</div>

			<template #preview-cover="{ file }">
				<div class="fileBox" v-if="logoaAtive == 1"><img src="@/assets/image/fusai/duix.png" /></div>

				<div class="preview-cover van-ellipsis"></div>
			</template>
		</van-uploader>
	</div>
</template>

<script>
import COS from 'cos-js-sdk-v5';
import md5 from 'blueimp-md5';
import dayjs from 'dayjs';
export default {
	name: 'pcHeader',
	data() {
		return {
			fileList: [],
			personalID: null, //存放url
			yuanUrl: null, //存放原来的base64
			multiple: true
		};
	},
	props: {
		uploadText: {
			type: String,
			default: '點擊上傳'
		},
		accept: {
			type: String,
			default: 'video/*'
		},
		logoaAtive: {
			type: String
		}
	},
	watch: {},
	components: {},
	created() {},
	mounted() {},
	methods: {
		uploadDelete(e) {
			console.log('删除的时候触发', e);
			let obj = {
				vaidoUrl: null,
				yuanVideo: null
			};
			console.log(obj);
			this.$emit('uploaderChange', obj);
		},
		// 图片上传
		afterRead(file, fileList) {
			this.$toast.loading({
				message: '正在上傳中...',
				forbidClick: true,
				duration: 0
			});
			this.personalID = null;
			// 此时可以自行将文件上传至服务器
			console.log(file);
			this.file = file.file;
			this.yuanUrl = file.content;
			this.cosBatchUpload({
				id: 1,
				category: '3.0_material',
				field: '3.0_material',
				fileList,
				onProgress: ({ data, fileName, key }) =>
					this.uploadProgress({
						fileName,
						key,
						data
					})
			});
		},
		cosBatchUpload(id, category, field, fileList, onProgress, success, allFinish) {
			this.$axios
				.get(this.$api.getCredential, { params: { id, category, field } })
			// this.$axios({
			// 	url: '/api/student/api/getCredential',
			// 	method: 'get',
			// 	data: { id, category, field }
			// })
				.then(res => {
					if (res.data.code === 0) {
						const {
							expiredTime,
							startTime,
							credentials: { tmpSecretKey, tmpSecretId, sessionToken }
						} = res.data.data;

						const callBackParams = {
							TmpSecretId: tmpSecretId,
							TmpSecretKey: tmpSecretKey,
							XCosSecurityToken: sessionToken,
							ExpiredTime: expiredTime,
							StartTime: startTime
						};
						return {
							callBackParams
						};
					}
				})
				.catch(err => {
					console.log(err, 'err');
					this.$toast.clear();
					this.fileList = [];
					this.personalID = null;
					this.$toast.fail('上傳失敗');
				})
				.then(res => {
					const { callBackParams } = res;
					const Region = 'accelerate';
					// const Region = 'ap-hongkong';
					const Bucket = 'materialv3-1256597606';
					const cos = new COS({
						getAuthorization: (options, callbackFn) => callbackFn(callBackParams)
					});

					const productDomain = window.location.hostname
						.split('.')
						.slice(1)
						.join('.');
					const isProductDomain = productDomain === 'chineserd.com';
					const envFolderName = isProductDomain ? 'prod' : 'dev';

					const type = '.' + this.file.name.split('.').pop();
					const taskId = md5(this.file.name + new Date().getTime());
					const Key = `chineserd/${envFolderName}/${dayjs().format('YYYY-MM')}/` + taskId + type;
					// cos.putObject(
					cos.sliceUploadFile(
						{
							Region,
							Bucket,
							Key,
							Body: this.file,
							taskId,
							onHashProgress: taskId => {
								console.log('taskId1');
							},
							// onTaskReady: taskId => {
							// 	this.$toast.loading({
							// 		message: '正在上傳中...',
							// 		forbidClick: true,
							// 		duration: 0
							// 	});
							// },
							onProgress: data => {
								this.$toast.loading({
									message: '上傳進度\n ' + Math.floor(data.percent * 100) + '%',
									forbidClick: true,
									duration: 0
								});
								if (Math.floor(data.percent * 100) == 100) {
									this.$toast.loading()
								}

								console.log('上傳進度: ' + Math.floor(data.percent * 100) + '%');
								onProgress({ data, fileName: this.file.name, key: Key });
							}
						},
						(err, data) => {
							console.log(err, data);
							if (err) {
								this.$toast.clear();
								this.personalID = null;
								this.fileList = [];
								return;
							}
							this.successUrl({ err, data, fileName: this.file.name, key: Key });
						}
					);
				});
		},
		successUrl(res) {
			console.log('https://' + res.data.Location, 'url');
			this.personalID = 'https://' + res.data.Location;
			let obj = {
				vaidoUrl: this.personalID,
				yuanVideo: this.yuanUrl
			};
			console.log(obj);
			this.$emit('uploaderChange', obj);
		}
	},
	computed: {}
};
</script>

<style lang="scss" scoped="scoped">
.uploaderBox {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	/deep/ .van-uploader__preview {
		width: 100%;
		height: 100%;
		margin: 0 !important;
	}
	/deep/ .van-uploader__preview-image,
	/deep/.van-uploader,
	/deep/.van-uploader__wrapper,
	/deep/.van-uploader__upload {
		width: 100% !important;
		height: 100% !important;
		justify-content: center;
		align-items: center;
	}
	/deep/ .van-image__img {
		object-fit: contain !important;
	}

	/deep/ .van-uploader__file {
		.van-icon {
			display: none !important;
		}
		.van-uploader__file-name {
			display: none !important;
		}
	}
	/deep/ .van-uploader__preview-cover {
	}
	.fileBox {
		width: 100%;
		height: 100%;
		margin: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100px;
			// height: 70px;
		}
	}
	.but {
		img {
			width: 100px;
			// height: 70px;
		}

		.buttext {
			width: 100%;
			text-align: center;
			margin-top: 15px;
			font-size: 12px;
			color: #4d4d4d;
		}
	}

	/deep/ .van-image {
		width: 100% !important;
		height: 130px !important;
	}
}
</style>
