<template>
	<section class="totalBox" v-show="viewShow"> 
		<pcView v-if="isPc" />
		<h5View v-else />
	</section>
</template>


<script>
	import {
		mapState
	} from 'vuex';
	import pcView from '@/components/home/pc/index'
	import h5View from '@/components/home/v4/index'
	import home from '@/mixin/home_mixin';
	
	export default {
		components: {},
		name: 'home',
		data() {
			return {
				dataUrl: null,
				painting: {}, 
			};
		},
		components: {
			pcView,
			h5View
		},
		watch: {

		},
		mixins: [home],
		created() { 
			this.home_mdInitBut();
		},
		
		beforeDestroy() {
		    
		},
		mounted() {

		},
		methods: {
			
		},
		computed: {
			isPc() {
				return this.$store.state.isPc;
			},
			viewShow() {
				return this.$store.state.viewShow;
			}
		},
		computed: {
			...mapState(['isPc', 'viewShow'])
		},
	};
</script>

<style lang="scss" scoped="scoped">
	.totalBox {
		width: 100%;
		height: 100%;

		.totalBox_view {
			position: fixed;
			z-index: 999;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
</style>
